import axios from 'axios'
import {
  Message
} from "element-ui"
import store from '@/store'
import router from '@/router'
import {
  _getRouterList
} from '@/utils/storage'

//请求拦截器
axios.interceptors.request.use(
  config => {
    //除了这几个登录注册和验证码以及风控的的接口都需要带上token
    const url = config.url
    if (url.indexOf('/api/Auth/Token') == -1 &&
      url.indexOf('/api/Auth/UserRegiste') == -1 &&
      url.indexOf('/api/Auth/GetIdentitys') == -1 &&
      url.indexOf('/api/Auth/SendAuthCode') == -1 &&
      url.indexOf('/api/Wallet/AuthBankCard') == -1 &&
      url.indexOf('/api/Auth/LoginByCode') == -1 &&
      url.indexOf('/api/DataConfig/GetSystemImageUrl') == -1 &&
      url.indexOf('/api/WayBillRisk/Risk') == -1 &&
      url.indexOf('/api/WayBillRisk/RiskTime') == -1 &&
      url.indexOf('/api/WayBillRisk/TrustRisk') == -1 &&
      url.indexOf('/api/WayBillRisk/TrustRiskTime') == -1 &&
      url.indexOf('/api/Auth/ResetPassword') == -1 &&
      url.indexOf('/api/ElectronicFence/Edit') == -1 &&
      url.indexOf('/api/ElectronicFence/Del') == -1 &&
      url.indexOf('/api/Auth/GetRegisterAgreement') == -1 &&
      url.indexOf('/api/ElectronicFence/ReverseCoordTX') == -1) {
      //循环菜单带上菜单id给后端
      const menus = _getRouterList()
      const currentUrl = router.history.current.fullPath
      for (let i = 0; i < menus.length; i++) {
        //请求头带上当前页面路由id
        if (menus[i].fullPath === currentUrl) {
          config.headers.menuId = menus[i].ID
          break
        }
      }
      //请求头带上token
      config.headers.Authorization = 'Bearer ' + store.getters.getToken
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//响应拦截器
axios.interceptors.response.use(
  response => {
    try {
      if (response.data.code == 0 && response.data.msg) {
        Message({
          type: response.data.type ? response.data.type : 'success',
          message: response.data.msg,
          duration: 3000
        })
      }
      if (response.data.code == 402 && response.data.msg) {
        Message({
          type: response.data.type ? response.data.type : 'error',
          message: response.data.msg,
          duration: 3000
        })
        let timer = setTimeout(() => {
          router.push('/login')
        }, 1000)
        return
      }
      if (response.data.code != 0 && response.data.msg) {
        if (response.config.url.indexOf('api/Driver/SaveDraftDriverList') > 0 || response.config.url.indexOf('api/Vehicle/SaveDraftVehicleList') > 0) {
          Message({
            showClose: true,
            type: response.data.type ? response.data.type : 'error',
            message: response.data.msg,
            duration: 0
          })
        } else {
          Message({
            type: response.data.type ? response.data.type : 'error',
            message: response.data.msg,
            duration: 3000
          })
        }

      }
      return (response.headers["content-type"].indexOf("text/plain") == -1 && response.headers["content-type"].indexOf("application/json") == -1) ? response : response.data
    } catch (error) {
      return response.data ? response.data : response
    }
  },
  error => {
    //未携带或者token错误都是401
    if (String(error).indexOf('401') != -1) {
      Message({
        type: 'error',
        message: '身份已过期，请重新登录',
        duration: 3000
      })
      router.push('/login')
    }
    if (String(error).indexOf('402') != -1) {
      Message({
        type: 'error',
        message: '您的账号已在其他地方登录',
        duration: 3000
      })
      let timer = setTimeout(() => {
        router.push('/login')
      }, 2000)
      // clearTimeout(timer)
    }
    //处理风控接口
    if (error.config.url.indexOf('/api/WayBillRisk/Risk') != -1 || error.config.url.indexOf('/api/File/DownErrorTrustBills') != -1) {
      return Promise.resolve(JSON.parse(error.config.data))
    }
    return Promise.resolve(error.response)
  }

)

export default axios