import { _setFullInfo, _getFullInfo, _setToken, _getToken, _setName, _getName, _setPhone, _getPhone, _setIdCard, _getIdCard, _getIsAdmin, _setUserId, _getUserId, _getEnterpriserAddress, _setEnterpriserAddress, _setIsCarCaptain, _getIsCarCaptain, _setCanTransfer, _getCanTransfer } from '@/utils/storage'

const app = {
    state: {
        token: '',
        ChineseName: '',
        phone: '',
        idCard: '',
        isAdmin: '',
        FullInfo: 0,
        userId: '',
        enterpriserAddress: '',
        isCarCaptain: '',
        CanTransfer: ''
    },
    mutations: {
        //设置用户信息
        setToken(state, payload) {
            state.token = payload
            //localStorage缓存一份
            _setToken(payload)
        },
        //设置用户是否能转账
        setCanTransfer(state, payload) {
            state.CanTransfer = payload
            //localStorage缓存一份
            _setCanTransfer(payload)
        },
        //设置用户信息
        setName(state, payload) {
            state.ChineseName = payload
            //localStorage缓存一份
            _setName(payload)
        },
        // 设置手机号
        setPhone(state, payload) {
            state.phone = payload
            //localStorage缓存一份
            _setPhone(payload)
        },
        // 设置身份证号
        setIdCard(state, payload) {
            state.idCard = payload
            //localStorage缓存一份
            _setIdCard(payload)
        },
        // 设置企业地址
        setEnterpriserAddress(state, payload) {
            state.enterpriserAddress = payload
            //localStorage缓存一份
            _setEnterpriserAddress(payload)
        },
        // 设置货主服务商信息页面填写状态
        setFullInfo(state, payload) {
            state.FullInfo = payload
            //localStorage缓存一份
            _setFullInfo(payload)
        },
        //设置是否是管理员身份
        setIsAdmin(state, payload) {
            state.isAdmin = payload
            //localStorage缓存一份
            _setIsAdmin(payload)
        },
        // 设置UserId
        setUserId(state, payload) {
            state.userId = payload
            //localStorage缓存一份
            _setUserId(payload)
        },
        // 设置是否是车队长身份
        setIsCarCaptain(state, payload) {
            console.log('payload', payload)
            state.isCarCaptain = payload
            //localStorage缓存一份
            _setIsCarCaptain(payload)
        }
    },
    getters: {
        //获取货主服务商信息页面填写状态
        getFullInfo(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.FullInfo) {
                state.FullInfo = _getFullInfo()
            }
            return state.FullInfo
        },
        // 获取用户能否转账
        getCanTransfer(state, payload) {
            if (!state.CanTransfer) {
                state.CanTransfer = _getCanTransfer()
            }
            return state.CanTransfer
        },
        //获取用户信息
        getToken(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.token) {
                state.token = _getToken()
            }
            return state.token
        },
        //获取用户信息
        getName(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.ChineseName) {
                state.ChineseName = _getName()
            }
            return state.ChineseName
        },
        //获取用户手机
        getPhone(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.phone) {
                state.phone = _getPhone()
            }
            return state.phone
        },
        //获取用户身份证号
        getIdCard(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.idCard) {
                state.idCard = _getIdCard()
            }
            return state.idCard
        },
        //获取企业地址
        getEnterpriserAddress(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.enterpriserAddress) {
                state.enterpriserAddress = _getEnterpriserAddress()
            }
            return state.enterpriserAddress
        },
        //获取是否是管理员身份
        getIsAdmin(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.isAdmin) {
                state.isAdmin = _getIsAdmin()
            }
            return state.isAdmin
        },
        // 获取UserId
        getUserId(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.userId) {
                state.userId = _getUserId()
            }
            return state.userId
        },
        // 获取是否是车队长身份
        getIsCarCaptain(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.isCarCaptain) {
                state.isCarCaptain = _getIsCarCaptain()
            }
            return state.isCarCaptain
        },
    }
}

export default app