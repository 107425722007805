//运税宝
let _host = "";
//风控
let _riskHost = "";
//阿里云OSShost
let _ossHost = "https://res.gts56.com/fzkj/web/front/YSB_PC/";
// 其他运单 好伙伴host
let _friendHost = "";
// 图片上传
let _fileUpload = "";
// 开放平台地址
let _openApi = "https://res.gts56.com/fzkj/sdk/demo_openapi.html"; // 开放平台 演示地址（测试也用这个）

if (process.env.NODE_ENV == "production") {
  //生产环境
  _host = "https://vapi.gts56.com/ysb"; //正式环境线上
  _riskHost = "https://vapi.gts56.com/risk"; //阿里云线上风控服务
  _friendHost = "https://vapi.gts56.com/tp"; // 好伙伴其他运单接口  正式
  _fileUpload = "https://vapi.gts56.com/file"; // 正式环境
  _openApi = "https://res.gts56.com/fzkj/sdk/prod_openapi.html"; // 开放平台 正式地址

  // _host = "https://vapi.gts56.com/ysbdemo"; //演示环境
  // _riskHost = "https://vapi.gts56.com/riskdemo"; //阿里云演示风控服务
  // _friendHost = "https://vapi.gts56.com/tpdemo"; //演示环境
  // _fileUpload = "https://vapi.gts56.com/filedemo"; // 演示环境
} else if (process.env.NODE_ENV == "development") {
  //开发环境
  // _host = "https://vapi.gts56.com/ysb"; //正式环境线上
  // _host = 'https://vapi.gts56.com/ysbdemo' //演示环境线上
  // _host = "http://192.168.1.199:10000/ysb"; //测试
  // _host = "http://192.168.1.241:5000"; //李
  // _host = 'http://192.168.1.251:5000' //朱
  // _host = 'http://192.168.1.240:5000' //董
  _host = "http://192.168.1.241:6040"; //李
  // _host = 'http://192.168.1.162:5000' //董
  // _friendHost = 'http://192.168.1.199:10000/tp' // 好伙伴其他运单接口
  // _riskHost = 'https://risk.ysb.gts56.com:8888' //阿里云线上风控服务
  // _riskHost = 'https://vapi.gts56.com/riskdemo' //阿里云演示风控服务
  // _riskHost = "http://192.168.1.199:30201"; //内网线上风控服务
  // _riskHost = 'http://192.168.1.241:6010' //风控服务 -> 李
  //  _riskHost = 'http://192.168.1.251:5000' //风控服务 -> 朱
  // _friendHost = "http://192.168.1.51:2023"; // 好伙伴其他运单接口
  // _friendHost = 'http://192.168.1.199:10000/tp' // 好伙伴其他运单接口
  _riskHost = "http://192.168.1.51:5000"; //风控服务 -> 李
  _fileUpload = "http://192.168.1.199:10000/file"; // 测试环境
} else if (process.env.NODE_ENV == "local") {
  //测试环境
  _host = "http://192.168.1.199:10000/ysb"; //内网
  _riskHost = "http://192.168.1.199:10000/risk"; //内网
  _friendHost = "http://192.168.1.199:10000/tp"; // 好伙伴其他运单接口
  _fileUpload = "http://192.168.1.199:10000/file"; // 测试环境
}

//运税宝
export let host = _host;
//风控
export let riskHost = _riskHost;
//阿里云OSShost
export let ossHost = _ossHost;
// 好伙伴api
export let friendHost = _friendHost;
// 文件上传
export let fileUpload = _fileUpload;
// 开放平台
export let openApi = _openApi;

// fileUpload文件上传备注
// filetype类型:
// Enterprise(企业资料：企业证照及其他企业相关文件) Driver(司机资料：司机证照及其他司机相关文件) Receipt(电子回单：线上线下电子回单)
// Vehicle(车辆资料：车辆证照及车辆相关文件) Contract(合同资料：线上线下合同) Evidence(凭证：除了电子回单、合同的其他凭证)
// Invoice(发票：发票相关文件) Download(下载/导出)  Other(其他：没有确定的类型，先考虑要不要确定类型，再放入其他目录)  Ship(水运证件)
