import Vue from 'vue'
import VueRouter from 'vue-router'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    name: '首页',
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('@/views/register')
  },
  {
    path: '/accredit',
    name: '授权结果',
    component: () => import('@/views/common/accredit')
  },
  {
    path: '/GYBFence/index',
    name: '围栏管理',
    component: () => import('@/views/common/GYBFence/index')
  },
  {
    path: '/ydpdfModel',
    name: '运单信息',
    component: () => import('@/views/common/printYdpdfModel')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
