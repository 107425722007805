import * as signalR from '@aspnet/signalr'
import store from '@/store'
import { host } from '@/api/config/host'

// var transport = signalR.TransportType.LongPolling; //连接类型
// var connection = new signalR.HubConnection(`${host}/msg`, { transport: transport }); //创建连接对象
const signal = new signalR.HubConnectionBuilder()
　　　//服务器地址
    .withUrl(`${host}/msg`,{accessTokenFactory: () =>  store.getters.getToken })
    .build()
    // signal.serverTimeoutInMilliseconds = 24e4; 
    // signal.keepAliveIntervalInMilliseconds = 12e4;
    const signalr = function () {
    var hub
    if (hub === undefined) {
        hub = signal
    }
    return hub
} 
let num = 0
//  自动重连
async function start () {
  try {
    await signal.start()
  } catch (err) {
    
    if(num >=10) return
    num++
    setTimeout(() => start(), 1e4)
  
  }
}

signal.onclose(async () => {
  await start()
}) 
if(store.getters.getToken) {
 start()
}
//将创建的signal赋值给Vue实例
export default {
   // install方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
    install: function(Vue) {
        Vue.prototype.signalr = signal
    }
}