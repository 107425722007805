<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from './store'
export default {
  mounted() {
    document.addEventListener('visibilitychange', this.checkNameExpired);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.checkNameExpired);
  },
  methods: {
    checkNameExpired() {
      if (document.hidden == false) {
        let storageArr = ["token", "name", "FullInfo", "phone", "idCard"]
        if (global.token != store.getters.getToken) {
          global.token = store.getters.getToken //当前登录的用户信息与全局存的用户信息不同，覆盖新的用户信息，否则是最初设的值
        }
        // if (global.CanTransfer != store.getters.CanTransfer) {
        //   global.CanTransfer = store.getters.CanTransfer
        // }
        if (global.name != store.getters.getName) {
          global.name = store.getters.getName
        }
        if (global.FullInfo != store.getters.getFullInfo) {
          global.FullInfo = store.getters.getFullInfo
        }
        if (global.idCard != store.getters.getIdCard) {
          global.idCard = store.getters.getIdCard
        }
        if (global.phone != store.getters.getPhone) {
          global.phone = store.getters.getPhone
        }
        localStorage.setItem("token", global.token)
        // localStorage.setItem("CanTransfer", global.CanTransfer)
        localStorage.setItem("name", global.name)
        localStorage.setItem("FullInfo", global.FullInfo)
        localStorage.setItem("phone", global.phone)
        localStorage.setItem("idCard", global.idCard)
      }
    }
  }

}
</script>

<!--公共样式-->
<style lang="scss">
.required {
  font-size: 26px;
  color: #f56c6c;
  position: relative;
  bottom: -10px;
}

.topView {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
  padding: 18px 10px 0 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);

  .searchBox {
    display: flex;
    justify-content: space-between;
  }

  .topbtns {
    margin-left: 30px;
    height: 36px;
    display: flex;
  }

  .headLeft {
    width: 12%;
    display: flex;
    justify-content: space-between;
    vertical-align: top;
    margin-top: 4px;

    img {
      cursor: pointer;
    }
  }

  .btns {
    margin: 0 0 20px 20px;
  }
}

.pageTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #2081ff;
  margin: 18px 0 16px 0;
  display: flex;
  justify-content: space-between;

  .settleBox {
    width: 718px;
    background: #2081ff;
    box-shadow: 0px 1px 15px rgba(32, 129, 255, 0.26);
    border-radius: 6px;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #ffffff;

    div {
      display: flex;

      div {
        width: 33.3%;

        span {
          font-size: 14px;
          font-weight: bold;
          color: #ffc117;
          display: inline-block;
          vertical-align: top;
          margin-top: 2px;
        }
      }
    }
  }
}

.facilityBox {
  min-width: 1070px;
  height: calc(100vh - 106px);
  background: white;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
}

// .el-loading-spinner {
//   font-size: 80px;
//   font-weight: bold;
//   width: 60px;
//   height: 60px;
// }

//修改文字的大小
// .el-loading-mask .el-loading-spinner .el-loading-text {
//   font-size: 18px;
//   color:#fff
// }

.el-tooltip__popper {
  max-width: 30%
}
</style>