
const app = {
    state: {
        invoiceTaskID: '', //发票任务id
        waybillType: {
            taskType: '', //业务类型
            taskTypeId: '', //业务类型id
            rateType: '', //发票类型
            sceneType: '', //场景类型
            sceneId: null, //场景id
            waybillList: [], //上传运单第一步所需要的运单列表
        }
    },
    mutations: {
        //设置发票任务id
        setInvoiceTaskID(state, payload) {
            state.invoiceTaskID = payload
        },
        //设置运单类型
        setWaybillType(state, payload) {
            state.waybillType.waybillList = payload.waybillList
            state.waybillType.taskType = payload.taskType
            state.waybillType.taskTypeId = payload.taskTypeId
            state.waybillType.rateType = payload.rateType
            state.waybillType.sceneType = payload.sceneType
            state.waybillType.sceneId = payload.sceneId
        },
    },
    getters: {
        //获取发票任务id
        getInvoiceTaskID(state, payload) {
            return state.invoiceTaskID
        },
        //获取运单类型
        getWaybillType(state, payload) {
            return state.waybillType
        },
    }
}

export default app