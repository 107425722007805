import Vue from 'vue'
import Vuex from 'vuex'

import createLogger from 'vuex/dist/logger'
const debug = process.env.NODE_ENV !== 'production'

import userinfo from './userinfo/userinfo'
import waybill from './waybill/waybill'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userinfo, //用户信息
    waybill, //运单信息
  },
  plugins: debug ? [createLogger()] : []
})