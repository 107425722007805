import CryptoJS from "crypto-js";

//存储token
export const _setToken = (token) => {
  localStorage.setItem("token", token);
};
//取出token
export const _getToken = () => {
  return localStorage.getItem("token");
};

//存储用户昵称
export const _setName = (name) => {
  localStorage.setItem("name", name);
};
//取出用户昵称
export const _getName = () => {
  return localStorage.getItem("name");
};
//存储用户能否转账
export const _setCanTransfer = (CanTransfer) => {
  localStorage.setItem("CanTransfer", CanTransfer);
};
// 取出用户能否转账
export const _getCanTransfer = () => {
  return eval(localStorage.getItem("CanTransfer"));
};
//存储货主服务商信息页面填写状态
export const _setFullInfo = (num) => {
  localStorage.setItem("FullInfo", num);
};
//取出货主服务商信息页面填写状态
export const _getFullInfo = () => {
  return localStorage.getItem("FullInfo");
};
// 存储身份证号IDcard
export const _setIdCard = (idCard) => {
  localStorage.setItem("idCard", idCard);
};
//取出身份证号
export const _getIdCard = () => {
  return localStorage.getItem("idCard");
};
//删除身份号
export const _removeIdCard = () => {
  sessionStorage.removeItem("idCard");
};
// 存储企业地址
export const _setEnterpriserAddress = (enterpriserAddress) => {
  localStorage.setItem("enterpriserAddress", enterpriserAddress);
};
//取出企业地址
export const _getEnterpriserAddress = () => {
  return localStorage.getItem("enterpriserAddress");
};
//删除企业地址
export const _removeEnterpriserAddress = () => {
  sessionStorage.removeItem("enterpriserAddress");
};
// 存储手机号码
export const _setPhone = (phone) => {
  localStorage.setItem("phone", phone);
};
//取出手机号码
export const _getPhone = () => {
  return localStorage.getItem("phone");
};
//删除手机号码
export const _removePhone = () => {
  sessionStorage.removeItem("phone");
};
//存储角色id
export const _setRoleId = (roleId) => {
  sessionStorage.setItem("roleId", roleId);
};
//取出角色id
export const _getRoleId = () => {
  return sessionStorage.getItem("roleId");
};
//删除角色id
export const _removeRoleId = () => {
  sessionStorage.removeItem("roleId");
};
//存储是否为管理员
export const _setIsAdmin = (isAdmin) => {
  sessionStorage.setItem("isAdmin", isAdmin);
};
//取出是否为管理员
export const _getIsAdmin = () => {
  return sessionStorage.getItem("isAdmin");
};
//删除是否为管理员
export const _removeIsAdmin = () => {
  sessionStorage.removeItem("isAdmin");
};
//存储角色父id
export const _setParentRoleId = (parentRoleId) => {
  sessionStorage.setItem("parentRoleId", parentRoleId);
};
//取出角色父id
export const _getParentRoleId = () => {
  return sessionStorage.getItem("parentRoleId");
};
//删除角色父id
export const _removeParentRoleId = () => {
  sessionStorage.removeItem("parentRoleId");
};

//存储路由列表
export const _setRouterList = (routerList) => {
  const cipher = CryptoJS.AES.encrypt(
    JSON.stringify(routerList),
    "routerList",
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  sessionStorage.setItem("routerList", cipher.toString());
};
//取出路由列表
export const _getRouterList = (routerList) => {
  if (!sessionStorage.getItem("routerList")) return;
  const plaintext = CryptoJS.AES.decrypt(
    sessionStorage.getItem("routerList"),
    "routerList",
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  try {
    JSON.parse(plaintext.toString(CryptoJS.enc.Utf8));
    return JSON.parse(plaintext.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    return { userId: -1, menu: [] };
  }
};
//删除路由列表
export const _removeRouterList = () => {
  sessionStorage.removeItem("routerList");
};

//存储当前路由地址
export const _setCurrentRouterPath = (currentRouterPath) => {
  sessionStorage.setItem("currentRouterPath", currentRouterPath);
};
//取出当前路由地址
export const _getCurrentRouterPath = () => {
  return sessionStorage.getItem("currentRouterPath");
};
//删除当前路由地址
export const _removeCurrentRouterPath = () => {
  sessionStorage.removeItem("currentRouterPath");
};

// 储存路由子级ID
export const _setSonId = (sonId) => {
  sessionStorage.setItem("sonId", sonId);
};
// 取出路由子级ID
export const _getSonId = () => {
  return sessionStorage.getItem("sonId");
};

// 储存登录角色身份
export const _setIdentityName = (IdentityName) => {
  sessionStorage.setItem("IdentityName", IdentityName);
};
// 取出登录角色身份
export const _getIdentityName = () => {
  return sessionStorage.getItem("IdentityName");
};
// 移除登录角色身份
export const _removeIdentityName = () => {
  return sessionStorage.removeItem("IdentityName");
};
// 储存其他运单二次配置的账号
export const _setOhterWayBillAccount = (account) => {
  sessionStorage.setItem("ohterWayBillAccount", account);
};
// 取出其他运单二次配置的账号
export const _getOhterWayBillAccount = () => {
  return sessionStorage.getItem("ohterWayBillAccount");
};
// 移除其他运单二次配置的账号
export const _removeOhterWayBillAccount = () => {
  return sessionStorage.removeItem("ohterWayBillAccount");
};

// 储存平台配置数据
export const _setConfigData = (configData) => {
  sessionStorage.setItem("configData", configData);
};
// 取出平台配置数据
export const _getConfigData = () => {
  return sessionStorage.getItem("configData");
};
// 移除平台配置数据
export const _removeConfigData = () => {
  return sessionStorage.removeItem("configData");
};
// 设置用户ID
export const _setUserId = (userId) => {
  sessionStorage.setItem("userId", userId);
};
//取出用户ID
export const _getUserId = () => {
  return sessionStorage.getItem("userId");
};
// 删除用户ID
export const _removeUserId = () => {
  return sessionStorage.removeItem("userId");
};

// 设置企业名称
export const _setEnterpriseName = (nterpriseName) => {
  sessionStorage.setItem("nterpriseName", nterpriseName);
};
//取出企业名称
export const _getEnterpriseName = () => {
  return sessionStorage.getItem("nterpriseName");
};
// 删除企业名称
export const _removeEnterpriseName = () => {
  return sessionStorage.removeItem("nterpriseName");
};

//存储企业审核状态
export const _setEnterpriserStatusName = (enterpriserStatusName) => {
  sessionStorage.setItem("enterpriserStatusName", enterpriserStatusName);
};
//取出企业审核状态
export const _getEnterpriserStatusName = () => {
  return sessionStorage.getItem("enterpriserStatusName");
};
//删除企业审核状态
export const _removeEnterpriserStatusName = () => {
  sessionStorage.removeItem("enterpriserStatusName");
};

//存储是否为车队长身份
export const _setIsCarCaptain = (isCarCaptain) => {
  sessionStorage.setItem("isCarCaptain", isCarCaptain);
};
//取出是否为车队长身份
export const _getIsCarCaptain = () => {
  return eval(sessionStorage.getItem("isCarCaptain"));
};

// 存储手机号码
export const _setConfigUserId = (userId) => {
  localStorage.setItem("ConfigUserId", userId);
};
//取出手机号码
export const _getConfigUserId = () => {
  return localStorage.getItem("ConfigUserId");
};
