//把参数转换成拼接字符串的形式
export const _paramsToQueryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

//日期格式化
export const _dateFormat = (params) => {
  var date = new Date(params);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = date.getDate();
  day = day > 9 ? day : "0" + day;
  var hh = date.getHours();
  hh = hh > 9 ? hh : "0" + hh;
  var mm = date.getMinutes();
  mm = mm > 9 ? mm : "0" + mm;
  var ss = date.getSeconds();
  ss = ss > 9 ? ss : "0" + ss;
  return year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
};

//获取当前时间并且根据传入的格式来进行格式化
export const _getCurrentDate = (fmt) => {
  let date = new Date();
  let o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

//对象数组根据key值去重
export const _uniqueByKey = (arr, key) => {
  let result = [];
  result[0] = arr[0];
  arr.forEach((meta_item, i) => {
    //声明计数变量，如果源数组中的一个对象和result结果数组中的所有对象不同，就push
    let num = 0;
    result.forEach((r_item, j) => {
      if (meta_item[key] !== r_item[key]) {
        num++;
      }
      if (num === result.length) {
        result.push(meta_item);
      }
    });
  });
  return result;
};

export const _downloadFile = (url) => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none"; // 防止影响页面
  iframe.style.height = 0; // 防止影响页面
  iframe.src = url;
  document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
  setTimeout(() => {
    iframe.remove();
  }, 5 * 60 * 1000);
};

// 输入数字转换成大写中文金额  最高支持千万亿（千兆）
export const _convertCurrency = (money) => {
  //汉字的数字
  var cnNums = new Array(
    "零",
    "壹",
    "贰",
    "叁",
    "肆",
    "伍",
    "陆",
    "柒",
    "捌",
    "玖"
  );
  //基本单位
  var cnIntRadice = new Array("", "拾", "佰", "仟");
  //对应整数部分扩展单位
  var cnIntUnits = new Array("", "万", "亿", "兆");
  //对应小数部分单位
  var cnDecUnits = new Array("角", "分", "毫", "厘");
  //整数金额时后面跟的字符
  var cnInteger = "整";
  //整型完以后的单位
  var cnIntLast = "元";
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = "";
  //分离金额后用的数组，预定义
  var parts;
  if (money == "") {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return "";
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf(".") == -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != "") {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == "") {
    chineseStr += cnInteger;
  }
  return chineseStr;
};
//  数字转为以万为单位
export const _formatMoney = (money) => {
  // money = parseFloat(money);
  if (money == 0 || !money) {
    return money + "";
  } else if (money > 1 && money < 10000) {
    return money + "";
  } else {
    return (money / 10000).toFixed(2) + "万";
  }
};
// 输入正确的金额格式
export function inputPrice(num, limit) {
  var str = num.toString();
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换
  str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
  if (limit / 1 === 1) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, "$1"); // 小数点后只能输 1 位
  } else {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); // 小数点后只能输 2 位
  }
  return str;
}
// 网络地址转换base64
export const _getBase64 = (url) => {
  return new Promise((resolve, reject) => {
    var Img = new Image();
    var dataURL = "";
    Img.setAttribute("crossOrigin", "Anonymous");
    Img.src = url + "?v=" + Math.random();
    Img.onload = function () {
      // 要先确保图片完整获取到，这是个异步事件
      var canvas = document.createElement("canvas"); // 创建canvas元素
      var width = Img.width; // 确保canvas的尺寸和图片一样
      var height = Img.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
      dataURL = canvas.toDataURL("image/jpeg"); // 转换图片为dataURL
      resolve(dataURL);
    };
  });
};
//将base64转换为blob
export const _dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

//判断是否是父子路由关系  /a/b/c，/d/e/f  前三个斜杠前的内容是否相等 /a/b 是否等于 /d/e
export const _comparePath = (path1, path2) => {
  let strArr1 = path1.split("/");
  let strArr2 = path2.split("/");
  return strArr1[1] + "/" + strArr1[2] === strArr2[1] + "/" + strArr2[2];
};

export const _diffentArr = (fArr, cArr, field) => {
  let diffRes = [];
  let fDatas = [];
  let cDatas = [];
  for (let i in fArr) {
    let flg = false;
    for (let j in cArr) {
      if (cArr[j][field] === fArr[i][field]) {
        flg = true;
        break;
      }
    }
    if (!flg) {
      fDatas.push(fArr[i]);
    }
  }
  for (let i in cArr) {
    let flg = false;
    for (let j in fArr) {
      if (fArr[j][field] === cArr[i][field]) {
        flg = true;
        break;
      }
    }
    if (!flg) {
      cDatas.push(cArr[i]);
    }
  }
  diffRes.push(...cDatas.concat(fDatas));
  return diffRes;
};

export const _getCurrentTime = () => {
  var myDate = new Date();
  var year = myDate.getFullYear(); //获取当前年
  var mon = myDate.getMonth() + 1; //获取当前月
  var date = myDate.getDate(); //获取当前日
  var hours = myDate.getHours(); //获取当前小时
  var minutes = myDate.getMinutes(); //获取当前分钟
  var seconds = myDate.getSeconds(); //获取当前秒
  var now =
    year + "-" + mon + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  return now;
};

// 生成唯一值
export const GenNonDuplicateID = () => {
  let idStr = Date.now().toString(36);
  idStr += Math.random().toString(36).substr(2);
  return idStr;
};

import { GetAuthorityByIdCard } from "@/api/transport/driverManage/index";
import { GetAuthorityByCarNumber } from "@/api/transport/vehicleManage/index";

// 通过身份证识别发证机关
export const getAuthorityByIdCard = (idcard) => {
  return GetAuthorityByIdCard({ idcard }).then((res) => {
    return res;
  });
};

// 通过车牌号识别发证机关
export const getAuthorityByCarNumber = (carnumber) => {
  return GetAuthorityByCarNumber({ carnumber }).then((res) => {
    return res;
  });
};

//时间排序
//prop：对象数组排序的键，
//align：排序方式，"positive"正序，"inverted"倒序。
export const compareTime = (prop, align) => {
  return function (a, b) {
    var value1 = a[prop];
    var value2 = b[prop];
    if (align == "positive") {
      //正序
      return new Date(value1) - new Date(value2);
    } else if (align == "inverted") {
      //倒序
      return new Date(value2) - new Date(value1);
    }
  };
};

// 表格列排序
export const columnCompare = (propertyName, sort) => {
  return function (obj1, obj2) {
    var value1 = obj1[propertyName];

    var value2 = obj2[propertyName];

    if (typeof value1 === "string" && typeof value2 === "string") {
      const res = value1.localeCompare(value2, "zh");

      return sort === "ascending" ? res : -res;
    } else {
      if (value1 <= value2) {
        return sort === "ascending" ? -1 : 1;
      } else if (value1 > value2) {
        return sort === "ascending" ? 1 : -1;
      }
    }
  };
};
