import { _getParentRoleId, _getPhone } from "@/utils/storage";
import { _uniqueByKey } from "@/utils/utils";
import VueRouter from "vue-router";
import { Message } from "element-ui";

export const _generateRoute = (router, routerListObj) => {
  if (!routerListObj) return;
  let { userId, menu: routerList = [] } = routerListObj || {};

  // 不是自己账号的路由信息， 提示异常
  if (userId != _getPhone()) {
    Message({
      message: "账号信息异常，请重新登录",
      type: "error",
    });
    setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
      router.push("/login");
    }, 1500);
  }

  if (!routerList || routerList.length == 0) return;

  //根据ID值去重
  routerList = _uniqueByKey(routerList, "ID");

  //通过roleId判断加载哪些页面
  const parentRoleId = _getParentRoleId();

  //路由懒加载
  const loadView = (path, componentUrl) => {
    //排除不用动态加载的页面  登录 注册 主页
    if (path == "/" || path == "/login" || path == "/register" || path == "/printYdpdfModel") {
      return () => import(`@/views${path}`);
    }
    if (parentRoleId == 1) {
      //平台 platform
      return () => import(`@/views/platform${componentUrl}`);
    } else if (parentRoleId == 2 || parentRoleId == 5) {
      //2:货主 boss,  5:车队长
      return () => import(`@/views/boss${componentUrl}`);
    } else if (parentRoleId == 3) {
      //平台 supplier
      return () => import(`@/views/supplier${componentUrl}`);
    } else if (parentRoleId == 4) {
      //代理商 agent
      return () => import(`@/views/agent${componentUrl}`);
    } else if (parentRoleId == 6) {
      //管理员 admin
      return () => import(`@/views/admin${componentUrl}`);
    }
  };

  //递归生成路由结构
  const getRouterList = (data, ParentID) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let node = data[i];
      if (node.ParentID == ParentID) {
        let newNode = {};
        newNode.children = getRouterList(data, node.ID);
        newNode.path = node.Path;
        newNode.name = node.Name;
        newNode.redirect = node.Redirect;
        newNode.id = node.ID;
        newNode.component = loadView(node.Path, node.NavigateUrl);
        newNode.fullPath = node.fullPath;
        newNode.imageUrl = node.ImageUrl;
        newNode.navigateUrl = node.NavigateUrl;
        newNode.parentID = node.ParentID;
        newNode.remark = node.Remark;
        newNode.sortIndex = node.SortIndex;
        newNode.viewPowerID = node.ViewPowerID;
        arr.push(newNode);
      }
    }
    return arr;
  };

  //调用生成路由
  let newRouterList = getRouterList(routerList);

  //动态添加路由
  router.onReady(() => {
    router.$addRoutes = (params) => {
      router.matcher = new VueRouter({ mode: "history" }).matcher;
      router.addRoutes(params);
    };
    router.$addRoutes(newRouterList);
    router.options.routes = router.options.routes.concat(newRouterList);
  });
};
